import integrationModule from "./integrationModule"

const IntegrationDataSourceDetail = {
    "TRIP_RELEASE": 607602837,
    "TRIP_UNRELEASE": 607602861,
    "RELEASED_TRIP_CANCEL": 626940653,
}

integrationModule.controller("integrationController", ["$scope", "integrationService", "loadingService", "messagesModal", "remoteExceptionHandler", "$q",
    function ($scope, integrationService, loadingService, messagesModal, remoteExceptionHandler) {

        $scope.integrationConfig = {}

        $scope.quantityFormats = [
            "br.com.neolog.r2.model.R2IntegrationQuantityFormats.UNITARY",
            "br.com.neolog.r2.model.R2IntegrationQuantityFormats.TOTAL_UNITS",
            "br.com.neolog.r2.model.R2IntegrationQuantityFormats.DECIMAL_UNITS"
        ]

        $scope.weightUnits = [
            "br.com.neolog.r2.model.R2IntegrationWeightUnits.NO_WEIGHT",
            "br.com.neolog.r2.model.R2IntegrationWeightUnits.TONNE",
            "br.com.neolog.r2.model.R2IntegrationWeightUnits.KILOGRAMS"
        ]

        $scope.volumeUnits = [
            "br.com.neolog.r2.model.R2IntegrationVolumeUnits.NO_VOLUME",
            "br.com.neolog.r2.model.R2IntegrationVolumeUnits.LITER",
            "br.com.neolog.r2.model.R2IntegrationVolumeUnits.CUBIC_METER"
        ]

        $scope.detailsTableOptions = {
            columns: [{
                header: "",
                cell: {
                    template: "<nlg-tdn-help doc-id='{{record.documentation}}' ng-if='record.documentation'></nlg-tdn-help>"
                },
                cellName: "integrationTaskHelp"
            }, {
                header: "edition.integrationDataSource.name",
                cell: "{{ getTypeBundle(record.task.type) | translate}}",
                width: "20%",
                cellName: "integrationTaskName"
            }, {
                header: "edition.integrationDataSource.url",
                cell: {
                    type: "text",
                    model: "record.url"
                },
                width: "40%",
                cellName: "integrationTaskURL"
            }, {
                header: "edition.integrationDataSource.maxResponseWait",
                cell: {
                    type: "number",
                    min: "10000",
                    decimals: "0",
                    model: "record.timeout"
                },
                cellName:"integrationTaskResponseMaxWait"
            }, {
                header: "edition.integrationDataSource.isAuthenticatedService",
                cell: {
                    type: "checkbox",
                    model: "record.authService",
                    click: "toggleAuthService(record)"
                },
                cellName:"integrationTaskAuthService"
            }, {
                header: "edition.integrationDataSource.user",
                cell: {
                    type: "text",
                    model: "record.authUsername",
                    ngDisabled: "! record.authService"
                },
                width: "10%",
                cellName:"integrationTaskAuthUser"
            }, {
                header: "edition.integrationDataSource.password",
                cell: {
                    type: "password",
                    model: "record.authPassword",
                    ngDisabled: "! record.authService"
                },
                width: "10%",
                cellName:"integrationTaskAuthPassword"
            }]
        }

        $scope.toggleAuthService = function (record){
            if(! record.authService){
                record.authUsername = null
                record.authPassword = null
            }
        }

        $scope.getTypeBundle = function (taskType) {
            return "edition.integrationDataSource." + taskType
        }

        function getConfig() {
            return loadingService(integrationService.getIntegrationConfig()).then((config) => {
                $scope.integrationConfig = config
                $scope.detailsTableOptions.records = config.dataSourceDetails
                    .map(detail => {
                        detail.documentation = IntegrationDataSourceDetail[detail.task.type]
                        return detail
                    })
            })
        }

        getConfig()

        $scope.save = function () {
            loadingService(integrationService.createOrUpdateConfig($scope.integrationConfig))
                .catch(remoteExceptionHandler())
                .then(() => getConfig())
                .then(() => {
                    messagesModal("dialog.success", [{
                        keyBundle: "entity.validator.successMessage",
                        parameters: []
                    }])
                })
        }
    }])